<template>
  <div ref="simDetails" id="sim-details"  :style="[{ width: getSimDetailWidth}, { maxHeight: toastHeight + 'px' }]">
    <div v-if="settingType =='simulation_type'" class="setting-container mt-2">
      <span>
        {{ formatSimSettingType(settingValue) }}
      </span>
    </div>
    <div v-if="settingType == 'ml_model' || settingType == 'scenario_note'" class="setting-container no-top-margin-all-descendants mt-2">
      <span class="mt-2">
        {{ this.settingValue }}
      </span>
    </div>
    <div v-if="settingType =='met_source'" class="setting-container no-top-margin-all-descendants mt-2">
      <div class="d-flex flex-column">
        <span class="mb-2"> {{ getMetSourceType }} : </span>
        <div v-if="modelledAtSite" class="mt-1 d-flex flex-column">
          <span> Lat: {{ this.settingValue.latlng.lat }}  </span>
          <span> Lng: {{ this.settingValue.latlng.lng }}  </span>
        </div>
        <span>
          {{ this.settingValue.name }}
        </span>
        <div class="d-flex flex-row justify-content-left align-items-center">
          <b-button v-if="canDownloadMet && !isFetchingMetData" size="sm" class="py-0 px-4 mt-4 ml-4" @click="downloadMetaData(settingValue.met_id, settingValue.type)">
            <span>Download</span>
          </b-button>
          <b-spinner class="mt-4 ml-5" small v-else label="spinner icon; loading data"></b-spinner>
        </div>
        
      </div>
    </div>
    <div v-if="settingType == 'roof_height'">
      <span class="mt-2" v-for="(item, index) in settingValue" :key="index">
        {{getMetricNameWithoutAnalysisType(item.metric)}}
        <p class="ml-2">
          {{item.buildingRoofHeight}}
        </p>
      </span>
    </div>
    <div v-if="settingType == 'building_enclosure'">
      <span class="mt-2" v-for="(item, index) in settingValue" :key="index">
        {{getMetricNameWithoutAnalysisType(item.metric)}}
        <p class="ml-2">
          {{item.buildingEnclosureClassification}}: {{mapBuildingEnclosureToName(item.buildingEnclosureClassification)}}
        </p>
      </span>
    </div>
    <div v-if="settingType == 'internal_pressure'">
      <span class="mt-2" v-for="(item, index) in settingValue" :key="index">
        {{getMetricNameWithoutAnalysisType(item.metric)}}
        <p class="ml-2">
          {{item.internalPressure}}
        </p>
      </span>
    </div>
    <div v-if="settingType == 'minutes_interval'">
      <span class="mt-2"></span>
        <p class="ml-2 mt-2">
          {{settingValue}} minutes
        </p>
    </div>
    <div v-if="settingType =='analysis_types'" class="setting-container no-top-margin-all-descendants mt-2">
      <span class="mt-2" v-for="(analysis_type, index) in settingValue" :key="index">
        {{analysis_type.name}}
        <div v-for="metric in analysis_type.metrics" :key="metric">
          <span class="ml-2">{{metric}} </span>
        </div>
      </span>
    </div>
    <div v-if="settingType == 'simulation_date'">
      <span class="mt-2">
        <p class="ml-2 mt-2">
          {{ settingValue }}
        </p>
      </span>
    </div>
    <div v-if="settingType == 'software_version'">
      <span class="mt-2">
        <p class="ml-2" v-for="item in getkeys(settingValue)" :key=item>
          {{item}}: {{ settingValue[item] }}
        </p>
      </span>
    </div>
    <div v-if="settingType == 'seasons'" class="setting-container no-top-margin-all-descendants mt-2">
      <span class="mt-2" v-for="item in settingValue" :key="item.Name">
        {{getMetricNameWithoutAnalysisType(item.metric)}}
        <div v-for="season in item.Seasons" :key="season.Name">
          <span class="ml-2">{{(season.Name || season.Season)}}: {{getSeasonMonths([season.Start])}} - {{getSeasonMonths([season.End])}}</span>
        </div>
        <div v-for="date in item.SingleDates" :key="date.Date" >
          <span class="ml-2">{{date.Date}}</span>
        </div>
      </span>
    </div>
    <div v-if="settingType == 'simulation_times'" class="setting-container no-top-margin-all-descendants mt-2">
      <span class="mt-2" v-for="item in settingValue" :key="item.Name">
        {{getMetricNameWithoutAnalysisType(item.metric)}}
        <div v-for="time in item.TimeofDay" :key="time.Name">
          <span class="ml-2">{{(time.Name || time.timeRange)}}: {{time.Start}} - {{time.End}}</span>
        </div>
        <div v-for="time in item.SingleDayHours" :key="time.Name">
          <span class="ml-2">{{time.Start}} - {{time.End}}</span>
        </div>
      </span>
    </div>
    <div v-if="settingType =='activity_level'"  class="setting-container no-top-margin-all-descendants mt-2">
      <span v-for="item in settingValue" :key="item.Name">
        <div>
          <span class="ml-2">{{item.activity}}</span>
        </div>
      </span>
    </div>
    <div v-if="settingType == 'clothing_profile'" class="setting-container no-top-margin-all-descendants mt-2">
      <span class="mt-2" v-for="item in settingValue" :key="item.Name">
        {{getMetricNameWithoutAnalysisType(item.metric)}} 
        <div v-if="item.customClothingProfileName">
          <span class="ml-2"> {{ item.customClothingProfileName }} </span> <br>
          <span class="ml-4 text-capitalize" v-for="(month, index) in months" :key="index"> {{month}}: {{ item.customClothingProfile[0][month] }} <br></span>
        </div>
        <div v-else>
          <span class="ml-2"> {{ item.clothingProfile }} </span>
        </div>
      </span>
    </div>
    <div v-if="settingType == 'wind_direction'">
      <div id="wind-direction" class="setting-container mt-2">
        <b-container fluid id="wind-direction-list" class="p-0 no-top-margin-all-descendants">
          <b-row v-for="item in settingValue" :key="item.id" >
            <b-col sm="4"><span>Direction: {{ item.windDirection }}</span></b-col>
            <b-col sm="5" v-if="companyIsRWDI && !isCladding"><span>Roughness: {{ item.roughness }}</span></b-col>
            <b-col sm="5" v-else><span>Terrain: {{ getTerrain(item.roughness) }}</span></b-col> 
          </b-row>
        </b-container>
      </div>
    </div>    
    <div v-if="settingType == 'confidence_index'">
      <div id="confidence-index" class="setting-container mt-2">
        <b-container fluid id="confidence-index-list" class="p-0 no-top-margin-all-descendants" >
          <b-row v-for="item in settingValue" :key="item.id" class="d-flex flex-row justify-content-between">
            <!-- printing the number of spaces based on wind-direction length in order to align text vertically to improve readibility -->
            <b-col sm="4"><span>Direction: {{ item.wind_direction }}</span></b-col>
            <b-col sm="5"><span>Confidence: {{ convertConfidenceIndex(item.confidence_index) }}</span></b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div v-if="settingType == 'keyboard'" :class="this.onlyShowKeyboardControls ? 'controls-container add-margin': 'controls-container'">
      <span id="keyboard-close" v-if="this.onlyShowKeyboardControls" @click="closeKeyboardMenu">  </span>
      <div
        class="control-item"
        v-for="item in this.controlKeys"
        :key-control-information="item"
        :key="item.key"
      >
        <div class="control-key">
          <span>{{ item.key }}</span>
        </div>
        <div class="control-description">
          <p class="small">{{ item.title }}</p>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
    <div v-if="settingType == 'return_period'" >
      <b-list-group class="mt-3">
        <b-list-group-item 
          style="background-color: inherit;" 
          class="p-0 .text-light" 
          v-for="(item, index) in settingValue" :key="index" 
        >
          {{getMetricNameWithoutAnalysisType(item.metric)}}
          <div class="mt-0"  v-for="(period, index) in item.returnPeriods" :key="index">
            <p class="small ml-2">Return Period: {{ period.returnPeriod }}</p>
            <p class="small ml-2" v-if="'designWindSpeed' in period">Design Wind Speed: {{ period.designWindSpeed }}</p>
            <p class="small ml-2" v-if="'durstFactorDropdownItem' in period">Design Wind Speed Avg Period:  {{ period.durstFactorDropdownItem }}</p>
            <p class="small ml-2" v-if="'durstFactor' in period">Durst Factor:  {{ period.durstFactor }}</p>
            <p class="small ml-2" v-if="'referenceHeight' in period">Measurement Height:  {{ period.referenceHeight }}</p>
            <hr class="mt-2 mb-2" v-if="showHR(item.returnPeriods.length)">
          </div>
        </b-list-group-item>
      </b-list-group> 
    </div>
    <div v-if="settingType == 'structural_origin'" >
      <b-list-group class="mt-3">
        <b-list-group-item 
          style="background-color: inherit;" 
          class="p-0 .text-light" 
          v-for="(item, index) in settingValue" :key="index" 
        >
          {{getMetricNameWithoutAnalysisType(item.metric)}}
          <div class="mt-0"  v-for="(origin, index) in item.structuralOrigin" :key="index">
            <p class="small ml-2">x: {{ origin.x }}</p>
            <p class="small ml-2">y: {{ origin.y }}</p>
            <p class="small ml-2">z: {{ origin.z }}</p>
          </div>
          <div class="mt-0">
            <p class="small ml-2">project north angle: {{ item.projectNorthAngle }}</p>
          </div>
        </b-list-group-item>
      </b-list-group> 
    </div>
    <div v-if="settingType == 'frontal_areas'" >
      <b-list-group class="mt-3">
        <b-list-group-item 
          style="background-color: inherit;" 
          class="p-0 .text-light" 
          v-for="(item, index) in settingValue" :key="index" 
        >
          {{getMetricNameWithoutAnalysisType(item.metric)}}
          <div class="mt-0"  v-for="(areas, index) in item.dragAndLiftAreas" :key="index">
            <p class="small ml-2">drag area: {{ areas.dragArea ? areas.dragArea : 'not provided' }}</p>
            <p class="small ml-2">lift area: {{ areas.liftArea ? areas.liftArea : 'not provided' }}</p>
          </div>
          <div class="mt-0"  v-for="(areas, index) in item.xDirAndYDirAreas" :key="index">
            <p class="small ml-2">x dir area: {{ areas.xDirArea ? areas.xDirArea : 'not provided' }}</p>
            <p class="small ml-2">y dir area: {{ areas.yDirArea ? areas.yDirArea : 'not provided' }}</p>
          </div>
        </b-list-group-item>
      </b-list-group> 
    </div>
  </div>
</template>

<script>
import {WINDTUNNELPROFILELIST, PLWROUGHNESSLIST} from '@/constants/terrain';
import { mapGetters } from 'vuex';
import fileDownloadService from '@/network/file-downloader';

export default {
  name: 'SimulationSettingItem',
  data() {
    return {
      returnPeriods: [],
      isFetchingMetData: false,
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    };
  },
  props: {
    settingValue: {
      type: [String, Number, Object, Array, Boolean],
      required: false 
    },
    settingType: {
      type: String,
      required: true
    },
    onlyShowKeyboardControls: {
      type: Boolean,
      required: false
    },
    toastHeight: {
      type: Number,
      required: false
    },
    isCladding: {
      required: true
    }
  },
  computed: {
    modelledAtSite(){
      return String(this.settingValue.type).toLowerCase() == 'modelled';
    },
    getMetSourceType(){
      if(this.settingValue.type == 'MEASURED')
        return 'Weather Station';
      if(this.settingValue.type == 'CUSTOM')
        return `${this.settingValue.file_format} File`;
      if(this.settingValue.type == 'FUTURE')
        return 'Future Met';
      return 'Modelled at Site';
    },
    getSimDetailWidth(){
      // contianer image app names can be quite long, so its calculated dynamically
      if(this.settingType == 'software_version'){
        const simParamWidth = Math.floor(this.settingValue.length *  0.44);
        //keeping a minimun width of 12.5 rem to match the design pattern
        if (simParamWidth < 12.5) return '12.5rem';
        return `${simParamWidth}rem`;
      }

      const isIncluded = ['seasons', 'wind_direction', 'keyboard', 'confidence_index', 'return_period'].includes(this.settingType);
      // The string length for the analysis_types param dramatically varies in length 
      // analysis_types is the only usecase now utilizes increaseSimDetailWidth therefore `analysisTypes[index].length > 24` specific to analysis_types
      // I suggest updating/testing `analysisTypes[index].length > 24` if the code below is updated to include other param types 
      let increaseSimDetailWidth = false;
      if(this.settingType == 'analysis_types' && this.settingValue[0]?.metrics?.length > 0){
        let analysisTypes = this.settingValue[0].metrics;
        if(analysisTypes[0]){
          for (let index = 0; index < analysisTypes?.length; index++) {
            if(analysisTypes[index].length > 24){
              increaseSimDetailWidth = true;
              break;
            }
          }
        }
      }
      if(isIncluded || increaseSimDetailWidth){
        return '18.25rem';
      }else{
        return '12.5rem';
      }
    },
    controlKeys() {
      return [{
        key: 'Ctrl',
        title: 'CTRL Key',
        description: 'Hold down the CTRL key to pan vertically or horizontally while mousing.'
      }, {
        key: 'Shift',
        title: 'SHIFT Key',
        description: 'Hold down the SHIFT key to pan while mousing.'
      }];
    },
    canDownloadMet() {
      return this.companyIsRWDI || this.userIsSuperuser;
    },
    companyIsRWDI() {
      return this.userCompany?.is_rwdi;
    },
    userIsSuperuser() {
      return this.loggedInUser?.is_superuser;
    },
    ...mapGetters(['userCompany', 'loggedInUser'])
  },
  
  methods: {
    getkeys(dict) {
      return Object.keys(dict);
    },
    async downloadMetaData(metSourceId, metType){
      this.isFetchingMetData = true;
      const projectId = Number(this.$route.params.id);
      if(metType == 'CUSTOM'){
        const response = await this.$store.dispatch('project/getCustomMetUrl', {projectId, metSourceId});
        await fileDownloadService.downloadFile(response.url, this.settingValue.name);
      } else {
        await this.$store.dispatch('project/getMetSourceData', {projectId, metSourceId});
      }
      this.isFetchingMetData = false;
    },
    formatSimSettingType(value){
      if(String(value).toUpperCase() == 'IMPORT')
        return 'Imported Results';
      if(String(value).toUpperCase() == 'ML')
        return 'AI';
      return value;
    },
    showHR(length){
      return length > 1;
    },
    convertConfidenceIndex(item){
      return item.toFixed(2);   
    },
    getMetricNameWithoutAnalysisType(metric_analysisType_str) {
      let last_dash_index = metric_analysisType_str.lastIndexOf('-');
      return metric_analysisType_str.substring(0, last_dash_index);
    },
    closeKeyboardMenu: function(){
      this.$emit('closeKeyboardMenu');
    },
    getSeasonMonths(monthNumber) {
      let monthList = {1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'};
      return monthList[monthNumber];
    },
    getTerrain(roughness) {
      if (this.isCladding) {
        for (let item of WINDTUNNELPROFILELIST) {
          if (item.value == roughness) {
            return item.label;
          }
        }
      } else {
        for (let item of PLWROUGHNESSLIST) {
          if (item.value == roughness) {
            return item.label;
          }
        }
      }
      return `z0 of ${roughness}`;
    },
    mapBuildingEnclosureToName(buildingEnclosureValue) {
      const enclosure_map = {
        '0': 'Fully Open',
        '0.55': 'Partially Open',
        '0.18': 'Fully Enclosed'
      };

      return enclosure_map[buildingEnclosureValue];
    }
  }
};
</script>

<style scoped>
  .add-margin{
    margin-top: -0.75rem !important;
  }

  #keyboard-close:hover{
    cursor: pointer;
  }

  #keyboard-close{
    position: absolute;
    top: 0.25rem;
    right: 0;
    margin: 0;
    padding: 0;
    background-image: url('~@/assets/svg/close-button.svg');
    background-color: white;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
  }
  .simulation-type-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .simulation-type-container span{
    font-size: 1.563rem;
  }

  #sim-details span{
    font-size: 0.813rem;
    font-weight: 400;
  }

  #sim-details .setting-container {
    padding-left: 0.625rem;
  }

  .controls-container {
    margin: 0.563rem 0 0.563rem 0.313rem;
  }

  .control-item {
    margin-bottom: 1.188rem;
  }

  div.control-item:last-child {
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }



  #confidence-index-list li, #wind-direction-list li {
    list-style-type: none;
    font-size: 0.813rem;
  }

  .control-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .control-key {
    height: 4em;
    width: 4em;
    min-width: 4em;
    max-height: 4em;
    border: 0.063rem solid var(--white);
    border-radius: 0.313rem;
    background: rgba(255, 255, 255, 0.15);
    font-family: "IBM Plex Mono";
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .control-description {
    margin-left: 0.625rem;
    margin-top: 0;
  }

  .control-description p {
    font-weight: 400;
    margin: 0;
    font-size: 0.813rem;
    line-height: 1rem;
  }

  h6 {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  span {
    font-size: 0.813rem;
    font-weight: 700;
  }
.metsource-download-btn:hover {
  cursor: pointer;
}
</style>