var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-4 pr-4",attrs:{"id":"probe-setter"}},[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._m(0),_c('div',{staticClass:"modal-footer mt-0 border-0 py-0"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.closeRefPicker}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.confirmOriginReference}},[_vm._v("OK")])])]),_c('hr',{staticClass:"structural-origin-hr"})]),_c('div',{staticClass:"d-flex justify-content-between mt-2"},[_c('FormulateForm',{on:{"submit":function($event){return _vm.showCustomProbePoint()}}},[_c('div',{staticClass:"mt-0 d-flex flex-row"},[_c('div',{staticClass:"d-flex mt-2"},[_c('div',{staticClass:"mt-0"},[_c('FormulateInput',{staticClass:"axis mr-2 my-0",attrs:{"type":"number","label":"x","placeholder":"x-axis","element-class":"probe-point-ctl-coord mt-0","errors-class":"d-none","validation":"required|validateXYZ","validation-rules":{
                validateXYZ: function (ref) {
                              var value = ref.value;

                              return (!Number.isNaN(value));
}
              }},on:{"validation":function($event){_vm.xAxisValidation = $event},"change":function($event){return _vm.showCustomProbePoint()}},model:{value:(_vm.rotatedProbePosition.x),callback:function ($$v) {_vm.$set(_vm.rotatedProbePosition, "x", $$v)},expression:"rotatedProbePosition.x"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.xAxisValidation.hasErrors),expression:"xAxisValidation.hasErrors"}],staticClass:"ml-3",staticStyle:{"color":"#960505","font-size":".8em","font-weight":"300","line-height":"1.5","margin-bottom":".25em"}},[_vm._v(" Error on Axis X")])],1),_c('div',{staticClass:"mt-0"},[_c('FormulateInput',{staticClass:"axis mr-2 my-0",attrs:{"label":"y","type":"number","placeholder":"y-axis","name":"y-1","id":"y-1","element-class":"probe-point-ctl-coord mt-0","errors-class":"d-none","validation":"required|validateXYZ","validation-rules":{
                validateXYZ: function (ref) {
                              var value = ref.value;

                              return (!Number.isNaN(value));
}
              }},on:{"validation":function($event){_vm.yAxisValidation = $event},"change":function($event){return _vm.showCustomProbePoint()}},model:{value:(_vm.rotatedProbePosition.y),callback:function ($$v) {_vm.$set(_vm.rotatedProbePosition, "y", $$v)},expression:"rotatedProbePosition.y"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.yAxisValidation.hasErrors),expression:"yAxisValidation.hasErrors"}],staticClass:"ml-3",staticStyle:{"color":"#960505","font-size":".8em","font-weight":"300","line-height":"1.5","margin-bottom":".25em"}},[_vm._v(" Error on Axis Y")])],1),_c('div',{staticClass:"mt-0"},[_c('FormulateInput',{staticClass:"axis mr-2 my-0",attrs:{"type":"number","label":"z","placeholder":"z-axis","name":"z-1","id":"z-1","element-class":"probe-point-ctl-coord mt-0","errors-class":"d-none","validation":"required|validateXYZ","validation-rules":{
                validateXYZ: function (ref) {
                              var value = ref.value;

                              return (!Number.isNaN(value));
}
              }},on:{"validation":function($event){_vm.zAxisValidation = $event},"change":function($event){return _vm.showCustomProbePoint()}},model:{value:(_vm.rotatedProbePosition.z),callback:function ($$v) {_vm.$set(_vm.rotatedProbePosition, "z", $$v)},expression:"rotatedProbePosition.z"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.zAxisValidation.hasErrors),expression:"zAxisValidation.hasErrors"}],staticClass:"ml-3",staticStyle:{"color":"#960505","font-size":".8em","font-weight":"300","line-height":"1.5","margin-bottom":".25em"}},[_vm._v(" Error on Axis Z")])],1)]),_c('div',{staticClass:"ml-4 mt-2"},[_c('FormulateInput',{staticClass:"ml-5 mb-2 mt-0",attrs:{"type":"number","label":"Angle","placeholder":"North Angle","element-class":"probe-point-ctl-coord mt-0","wrapper-class":"d-flex","errors-class":"list-unstyled mb-0","label-class":"ref-north-angle-label mb-0","validation":"between:-360,360,value","min":"-360","max":"360","validation-messages":{ between: 'Must be between -360 and 360'}},on:{"change":function($event){return _vm.showCustomProbePoint()}},model:{value:(_vm.localNorthAngle),callback:function ($$v) {_vm.localNorthAngle=$$v},expression:"localNorthAngle"}})],1)])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between mt-2"},[_c('p',{staticClass:"text-capitalize font-weight-bold",staticStyle:{"font-size":"medium"}},[_vm._v(" structural origin ")])])}]

export { render, staticRenderFns }