<template>
  <div @click="selectInsightType()" class="card" :style='cardStyle'>
    <div class="card-title">{{ insightType }}</div>
    <div v-if="insightType=='Scene'" id="scene-icon" class="card-img-top" alt=""></div>
    <div v-if="insightType=='Image'" id="image-icon" class="card-img-top" alt=""></div>
    <div v-if="insightType=='Report'" id="report-icon" class="card-img-top" alt=""></div>
    <div class="card-body">
      <p class="card-text">{{ descriptionText }}</p>
      <p class='card-text text-danger' v-if="insightType=='Scene' && studyHasNoResultLayers">
        Cannot select this type since there are no simulation results.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InsightTypeSelector',
  props: {
    insightType: {
      type: String,
      requred: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      insightTypeChosen: false
    };
  },
  methods: {
    selectInsightType() {
      if(!(this.insightType == 'Scene' && this.studyHasNoResultLayers))
        this.$store.dispatch('project/setInsightTypeSelected', this.insightType);
    }
  },
  computed: {
    selectedInsightType() {
      return this.$store.getters['project/insightTypeSelected'];
    },
    cardStyle() {
      if(this.insightType == 'Scene' && this.studyHasNoResultLayers) {
        return 'opacity: 65%';
      }

      if (this.selectedInsightType!=='') {
        if (this.selectedInsightType == this.insightType) {
          return 'box-shadow: 0px 0px 10px 0px #676E7666;';
        } else {
          return 'opacity:65%;';
        }
      } else {
        return '';
      }
    },
    descriptionText() {
      if (this.insightType=='Scene') {
        return 'An interactive insight based upon a view of the 3D model with an optional result layer.';
      } else if (this.insightType=='Image') {
        return 'An insight based on a static, 2D image, drawing, or photo.';
      } else {
        return 'An insight based on a specific page of a PDF report.';
      }
    },
    studyHasNoResultLayers() {
      return this.selectedStudy?.simulation_labels.filter(sim => sim.category === 'RESULT').length == 0;
    },
    ...mapGetters('project', ['selectedStudy'])
  },
  created() {
    if (this.$store.getters['project/insightTypeSelected'] == this.insightType) {
      this.insightTypeChosen = true;
    }
  },
  watch: {
    selectedInsightType(newValue) {
      if (newValue == this.insightType) {
        this.insightTypeChosen = true;
      } else {
        this.insightTypeChosen = false;
      }
    }
  }
};
</script>

<style scoped>
.selector-panel {
  width: 21%;
  height: 50%;
  background-color: lightgray;
  margin: 2%;
}

.card {
  max-width: 20rem;
  margin-top:0;
  background-color: #F6F7F9 ;
  border-radius: 0.6rem;
}

.card:hover {
  cursor: pointer;
}

.card-title {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding-bottom: 0;
  margin-bottom: 0;

  font-size: 1rem;
  font-weight: 550;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #676E76;
  text-transform: lowercase;
}

.card-title::first-letter {
  text-transform: uppercase;
}

#scene-icon {
  background-image: url('~@/assets/img/insights-scene.webp');
  width: 6rem;
  height: 6rem;
  margin-top: 1rem;
}
#image-icon {
  background-image: url('~@/assets/img/insights-image.webp');
  width: 5rem;
  height: 5rem;
}
#report-icon {
  background-image: url('~@/assets/img/insights-report.webp');
  width: 5rem;
  height: 5rem;
}

.card-img-top {
  margin: auto;
  margin-top: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-body {
  padding-top: 0;
}
.card-text {
  font-family: Inter;
  font-size: 0.89rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #676E76;
}

@media screen and (max-width: 80rem) {
  .card {
    max-width: 15rem !important;
  }
}
</style>