<template>
  <section id="annotation_creation_menu" class="p-2" v-if="showAnnotationCreationMenu && editInsightMode">
    <div class="border-bottom">
      <h6 class="d-inline">ANNOTATION PINS</h6>
      <span class="float-right mt-0 cursor-pointer">
        <b-icon icon="x" size="large" @click="setShowAnnotationCreationMenu(false)"></b-icon>
      </span>
    </div>
    <span v-for="(annotation, index) in insightPinTypes" :key="`${annotation.icon}-${index}`" 
      :class="['annotation-bg-circle rounded-circle m-1 d-inline-block cursor-pointer']"
      :style="`background: ${annotation.colour};`"
      @click='setSelectedDefaultAnnotationType(annotation)'>
      <img v-if='annotation.icon' class='annotation-icon-img' :src="`/annotation_icons/${annotation.icon}.webp`"/>
      <p v-if='annotation.tag_type == "letter"' class="annotation-tag">A</p>
      <p v-if='annotation.tag_type == "number"' class="annotation-tag">1</p>
    </span>
    <p @click="toggleMenuExpanded" class="cursor-pointer mt-2">
      {{ menuExpanded ? 'See Less' : 'See More'}}
    </p>
    <section v-if="menuExpanded">
      <h6 class="mt-2 pt-2 border-top">CUSTOMIZE PIN</h6>
      <span 
        v-for="tagIconChoice in tagIconChoices" :key="tagIconChoice"
        :class="['cursor-pointer px-2 d-inline-block mr-2 mt-1 mb-1 border rounded', selectedTagIcon.toLowerCase() == tagIconChoice.toLowerCase() ? 'selected' : '']"
        @click="setTagIconChoice(tagIconChoice)">
        {{ tagIconChoice }}
      </span>
      <section v-if="selectedTagIcon.toLowerCase() === 'icon'">
        <h6 class="mt-2">ICON</h6>
        <span v-for="icon in iconChoices" :key="icon" 
          :class="['annotation-bg-circle rounded-circle bg-secondary m-1 d-inline-block cursor-pointer', selectedIcon == icon ? 'selected' : '']"
          @click='setSelectedIcon(icon)'>
          <img class='annotation-icon-img' :src="`/annotation_icons/${icon}.webp`"/>
        </span>
      </section>
      <h6 class="mt-2">BACKGROUND</h6>
      <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
        <span 
          v-for="(colour, index) in colourChoices" :key="index"
          :class="['colour-choice rounded-circle mr-2', selectedBackgroundColour == colour ? 'selected-colour-choice' : '']"
          :style="`background-color: ${colour}`"
          @click="setBackgroundColour(colour)">
        </span>
      </div>
    </section>
    <b-button
      v-if="menuExpanded"
      id="create-annotation-button"
      variant="outline-primary"
      class="px-5 mt-2 float-right"
      @click="setAnnotationType">
      Create
    </b-button>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { 
  NEW_ANNOTATION_TYPE_NAME,
  ANNOTATION_ICONS,
  ANNOTATION_COLOURS
} from '@/constants/annotations';

export default {
  name: 'AnnotationCreationMenu',
  props: {
    showAnnotationCreationMenu: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      menuExpanded: false,
      selectedDefaultAnnotation: null,
      tagIconChoices: ['Number', 'Letter', 'Icon'],
      selectedTagIcon: 'Icon',
      selectedPinName: null,
      selectedPinDescription: null,
      selectedPinType: null,
      selectedPinTagType: null,
      iconChoices: ANNOTATION_ICONS,
      selectedIcon: null,
      colourChoices: ANNOTATION_COLOURS,
      selectedBackgroundColour: null
    };
  },
  mounted() {
    this.setSelectedDefaultAnnotationType(this.insightPinTypeTemplates[0]);
    this.setSelectedIcon(this.iconChoices[0]);
    this.setBackgroundColour(this.colourChoices[0]);
  },
  computed: {
    insightPinTypes() {
      let pinTemplateInPinTypes;
      let insightPinTypes = _.clone(this.currentInsight.pin_types);
      this.insightPinTypeTemplates.forEach(pinTypeTemplate => {
        pinTemplateInPinTypes = insightPinTypes?.some(pinType => {
          return pinType.tag_type == pinTypeTemplate.tag_type 
            && pinType.colour == pinTypeTemplate.colour 
            && pinType.icon == pinTypeTemplate.icon;
        });
        if(!pinTemplateInPinTypes) insightPinTypes.push(pinTypeTemplate);
      });
      return insightPinTypes;
    },
    ...mapGetters('project', ['editInsightMode', 'currentInsight', 'insightPinTypeTemplates'])
  },
  methods: {
    setSelectedDefaultAnnotationType(annotation) {
      if (annotation) {
        this.setTagIconChoice(annotation.tag_type);
        this.setSelectedIcon(annotation.icon);
        this.setBackgroundColour(annotation.colour);
        this.setSelectedPinName(annotation.name);
        this.setSelectedPinDescription(annotation.description);
        this.setSelectedPinType(annotation.type);
        this.setSelectedPinTagType(annotation.tag_type);
        this.selectedDefaultAnnotation = annotation;
        this.menuExpanded = false;
        this.setAnnotationType();
      }
    },
    setShowAnnotationCreationMenu(showMenu) {
      this.$emit('setShowAnnotationCreationMenu', showMenu);
    },
    toggleMenuExpanded() {
      this.menuExpanded = !this.menuExpanded;
    },
    setTagIconChoice(tagIconChoice) {
      this.selectedTagIcon = tagIconChoice;
      this.selectedDefaultAnnotation = null;
    },
    setSelectedIcon(icon) {
      this.selectedIcon = icon;
      this.selectedDefaultAnnotation = null;
    },
    setSelectedPinName(name) {
      this.selectedPinName = name;
      this.selectedDefaultAnnotation = null;
    },
    setSelectedPinDescription(description) {
      this.selectedPinDescription = description;
      this.selectedDefaultAnnotation = null;
    },
    setSelectedPinType(type) {
      this.selectedPinType = type;
      this.selectedDefaultAnnotation = null;
    },
    setSelectedPinTagType(tagType) {
      this.selectedPinTagType = tagType;
      this.selectedDefaultAnnotation = null;
    },
    setBackgroundColour(colour) {
      this.selectedBackgroundColour = colour;
      this.selectedDefaultAnnotation = null;
    },
    setAnnotationType() {
      let tag, icon;
      if(this.selectedTagIcon.toLowerCase() == 'number') {
        let numbers = this.currentInsight.pins.filter(pin => !isNaN(pin.tag) && pin.colour == this.selectedBackgroundColour).map(pin => Number(pin.tag));
        let maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0;
        tag = String(maxNumber + 1);
        icon = null;
      } else if (this.selectedTagIcon.toLowerCase() == 'letter') {
        let letters = this.currentInsight.pins.filter(pin => pin.tag?.match(/[A-Z]/i) && pin.colour == this.selectedBackgroundColour).map(pin => pin.tag);
        let maxLetter = letters.length > 0 ? letters.sort().pop() : '@'; // '@' comes before 'A'
        tag = String.fromCharCode(maxLetter.charCodeAt(0) + 1); // increment max letter by one ('A' becomes 'B'; '@' becomes 'A')
        icon = null;
      } else if (this.selectedTagIcon.toLowerCase() == 'icon') {
        tag = null;
        icon = this.selectedIcon;
      }

      if(!this.selectedDefaultAnnotation) {
        this.setSelectedPinType(null);
        this.setSelectedPinName(null);
        this.setSelectedPinDescription(null);
      }

      if(!this.selectedPinName) {
        this.setSelectedPinName(NEW_ANNOTATION_TYPE_NAME);
      }

      this.setAnnotationTypeToCreate({
        name: this.selectedPinName,
        description: this.selectedPinDescription,
        type: this.selectedPinType,
        tagType: this.selectedTagIcon,
        tag: tag,
        icon: icon,
        colour: this.selectedBackgroundColour
      });
      this.setShowAnnotationCreationMenu(false);
      this.$store.dispatch('project/setAnnotationsCompositionMode', true);
    },
    ...mapActions({
      setAnnotationTypeToCreate: 'project/setAnnotationTypeToCreate'
    })
  },
  watch: {
    editInsightMode(newValue) {
      if (!newValue) {
        this.$emit('setShowAnnotationCreationMenu', false);
        this.$store.dispatch('project/setAnnotationsCompositionMode', false);
      }
    }
  }
};
</script>

<style scoped>
#annotation_creation_menu {
  position: absolute;
  background: white;
  left: 28.1vw;
  top: 3.9rem;
  z-index: 1;
  font-size: 0.7rem;
  color: var(--grey-700);
  width: 235px;
  z-index: 4;
}

.cursor-pointer {
  cursor: pointer;
}

.selected {
  border: 2px rgb(77, 187, 255) solid !important;
}

.colour-choice {
  width: 1.2rem;
  height: 1.2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.selected-colour-choice {
  border: 2px rgb(77, 187, 255) solid;
  background-clip: content-box;
  padding: 1.6px;
}

.annotation-tag {
  width: inherit;
  line-height: 22.62px;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
}

.annotation-icon-img {
  width: 16px;
  height: 16px;
  padding: 3.31px;
}

.annotation-bg-circle {
  width: 22.62px;
  height: 22.62px;
  text-align: center;
  vertical-align: middle;
}
</style>
